import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import {
  typography,
  color,
  space,
  fontSize,
  fontFamily,
  letterSpacing,
} from "styled-system";

interface WrapperProps {
  mt?: {};
  mb?: {};
  px?: {};
  py?: {};
  pl?: {};
  pr?: {};
}

interface TextProps {
  fontSize?: {};
  px?: {};
  py?: {};
}

export const Wrapper = styled("div")<WrapperProps>`
  ${space}
  position: relative;
  width: 100%;
  background-color: ${themeGet("colors.bgColor")};

  .c-popup__contents {
    overflow-y: scroll;
  }

  .c-popup-contents {
    overflow-y: scroll;
  }
`;

export const ModalWrapper = styled("div")<WrapperProps>`
  ${space}
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 1000px;
  max-height: 600px;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;

  @media (max-width: ${themeGet("breakpoints.0")}) {
    max-width: 70vw;
    max-height: 60vh;
  }
`;

export const ModalWrapperInner = styled("div")<WrapperProps>`
  padding: 30px 40px;

  @media (max-width: ${themeGet("breakpoints.2")}) {
    padding: 10px 20px;
  }
  @media (max-width: ${themeGet("breakpoints.1")}) {
    padding: 10px 20px;
  }
  @media (max-width: ${themeGet("breakpoints.0")}) {
    padding: 10px 20px;
  }
`;

export const ButtonWrap = styled("div")<WrapperProps>`
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled("div")<WrapperProps>`
  /* position: absolute;
  right: 20px;
  bottom: 20px; */
  cursor: pointer;
  border: 2px solid #ddd;
  border-radius: 6px;
  text-align: center;
  font-weight: bold;
  color: #272727 !important;

  &:link,
  &:visited,
  &:hover {
    color: #272727 !important;
  }

  a {
    display: inline-block;
    padding: 12px 20px;
    color: #272727 !important;

    @media (max-width: ${themeGet("breakpoints.1")}) {
      font-size: 12px !important;
    }

    @media screen and (max-width: 767px) {
      padding: 8px 20px;
      font-size: 14px !important;
    }

    @media screen and (max-width: 380px) {
      padding: 8px 12px;
      font-size: 12px !important;
    }

    &:link,
    &:visited,
    &:hover {
      color: #272727 !important;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${themeGet("breakpoints.2")}) {
    right: 4%;
    bottom: 4%;
    padding: 8px 12px;
    font-size: 12px !important;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: ${themeGet("breakpoints.1")}) {
    right: 4%;
    bottom: 4%;
    padding: 8px 12px;
    font-size: 12px;
    font-size: 12px !important;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: ${themeGet("breakpoints.0")}) {
    right: 4%;
    bottom: 4%;
    padding: 8px 12px;
    font-size: 12px !important;

    &:hover {
      opacity: 1;
    }
  }
`;

export const ModalText = styled("p")<WrapperProps>`
  font-size: 16px;
  font-weight: bold;

  @media (max-width: ${themeGet("breakpoints.1")}) {
    font-size: 14px;
  }

  @media (max-width: ${themeGet("breakpoints.0")}) {
    font-size: 12px;
  }
`;
