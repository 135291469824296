import React from "react";
import Layout from "./layout";
import { Link, ModalRoutingContext } from "gatsby-plugin-modal-routing";
import { Wrapper, CloseButton, ButtonWrap } from "./style";

const ConditionalLayout = ({ children, ...rest }) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) =>
      modal ? (
        <React.Fragment>
          <ButtonWrap>
            <CloseButton className="c-popup-close-button">
              <Link to={closeTo}>閉じる</Link>
            </CloseButton>
          </ButtonWrap>
          <Wrapper>
            {children}
            {/* <ButtonWrap>
              <CloseButton className="c-popup-close-button">
                <Link to={closeTo}>閉じる</Link>
              </CloseButton>
            </ButtonWrap> */}
          </Wrapper>
        </React.Fragment>
      ) : (
        <Layout {...rest}>{children}</Layout>
      )
    }
  </ModalRoutingContext.Consumer>
);

export default ConditionalLayout;
