import React, { useState, useEffect } from "react";
import { Link } from "gatsby-plugin-modal-routing";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

import ConditionalLayout from "../components/ConditionalLayout/ConditionalLayout";
import Content, { HTMLContent } from "../components/Content";

export const IndexPopupPageTemplate = ({
  indexPopup,
  noticeContent,
  contentComponent,
}) => {
  useEffect(() => {
    checkHash();
  });

  const NoticeContent = contentComponent || Content;

  return (
    <ConditionalLayout>
      <h1 id="js-popup-title" className="c-popup-title">
        {indexPopup.title}
      </h1>

      <NoticeContent content={noticeContent} className={"c-popup-contents"} />
    </ConditionalLayout>
  );
};

IndexPopupPageTemplate.propTypes = {
  indexPopup: PropTypes.shape({
    title: PropTypes.string,
  }),
  contentComponent: PropTypes.func,
};

const IndexPopupPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { markdownRemark: post } = data;
  // const { markdownRemark: post } = data;

  return (
    <IndexPopupPageTemplate
      indexPopup={frontmatter.indexPopup}
      noticeContent={post.html}
      contentComponent={HTMLContent}
    />
  );
};

IndexPopupPage.propTypes = {
  // data: PropTypes.object,
  contentComponent: PropTypes.func,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

const checkHash = () => {
  let pathname = window.location.pathname;
};

export default IndexPopupPage;

export const IndexPopupPageQuery = graphql`
  query IndexPopupPageTemplate($id: String!) {
    markdownRemark(
      id: { eq: $id }
      frontmatter: { templateKey: { eq: "index-popup-page" } }
    ) {
      id
      html
      frontmatter {
        indexPopup {
          title
        }
      }
    }
  }
`;
