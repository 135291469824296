/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import { Link } from "gatsby";
import "./layout.css";

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <Link
          className="c-popup-contents__back-button"
          rel="noopener noreferrer"
          to={"/"}
        >
          {"< " + "戻る"}
        </Link>
        <main>{children}</main>
        <div className="c-popup-contents__center-back-button-wrap">
          <Link
            className="c-popup-contents__center-back-button"
            rel="noopener noreferrer"
            to={"/"}
          >
            TOPヘ戻る
          </Link>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
